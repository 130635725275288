import React from 'react';
import styles from './AsSeenSection.module.scss';
import Image from '@/src/shared/components/Image';
import { Section } from '../Section';
import { clsx } from 'clsx';

const medias = [
  'inman',
  'forbes',
  'bloomberg',
  'business',
  'housingwire',
  'techcrunch',
  'national',
];

export default function AsSeenSection() {
  return (
    <Section className={styles.container}>
      <h6 className={styles.title}>As seen in</h6>
      <div className={styles.logos}>
        {medias.map((media) => (
          <div className={styles.logo} key={media}>
            <div
              className={clsx(styles.imgWrapper, {
                [styles.inman]: media === 'inman',
                [styles.forbes]: media === 'forbes',
                [styles.bloomberg]: media === 'bloomberg',
                [styles.business]: media === 'business',
                [styles.housingwire]: media === 'housingwire',
                [styles.techcrunch]: media === 'techcrunch',
                [styles.national]: media === 'national',
              })}
            >
              <Image
                src={`/next-public/images/home/v6/company-logos/${media}.png`}
                alt={media}
                fill
              />
            </div>
          </div>
        ))}
      </div>
    </Section>
  );
}
